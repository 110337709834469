.c-tab-menu {
  margin-bottom: map-get($map: $spacers, $key: 5);
}

.c-tab-container {
  width: 900px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  margin: 0 auto;
  ol {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      padding-left: 0;
      border: 1px solid #070707;
      margin: 0 2px;
      @include media-breakpoint-down(sm) {
        width: 10%;
        margin-bottom: map-get($map: $spacers, $key: 2);
        padding: 1px;
      }
    }
  }

  h3 {
    color: $body-color;
  }
  .c-minheadline {
    color: $white;
  }
}

.c-btn-login {
  border: 1px solid map-get($map: $theme-colors, $key: primary);
  color: map-get($map: $theme-colors, $key: primary);
  @include media-breakpoint-down(md) {
    border: none;
    padding: 0;
    font-size: 0.8rem;
  }
  // background-color: #ffffff;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #eeeeee;
  }
}

.c-btn-newregist {
  display: block;
  width: 100%;
  padding-top: 32px;
  margin-bottom: map-get($map: $spacers, $key: 3);
  color: map-get($map: $theme-colors, $key: primary);
  font-weight: 700;
  font-size: 1.4rem;
  background: #cbece7; /* Old browsers */
  background: linear-gradient(
    to bottom,
    #cbece7 0%,
    #ffffff 50%,
    #acdef8 51%,
    #acdef8 100%
  );
  box-shadow: 3px 3px 10px 3px rgba($color: $body-color, $alpha: 0.2) inset,
    2px 2px 10px 2px rgba($color: $body-color, $alpha: 0.5);
  transition: filter 0.3s ease;
  &:hover {
    filter: brightness(1.15);
  }
}

.c-btn-free {
  display: block;
  width: 100%;
  padding-top: 18px;
  margin-bottom: map-get($map: $spacers, $key: 3);
  color: map-get($map: $theme-colors, $key: primary);
  font-weight: 700;
  font-size: 1.4rem;
  background: #b8eae8;
  background: linear-gradient(
    to bottom,
    #b8eae8 0%,
    #ffffff 50%,
    #8cddd9 51%,
    #8cddd9 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  box-shadow: 3px 3px 10px 3px rgba($color: $body-color, $alpha: 0.2) inset,
    2px 2px 10px 2px rgba($color: $body-color, $alpha: 0.5);
  transition: filter 0.3s ease;
  &:hover {
    filter: brightness(1.15);
  }
  span {
    display: block;
    font-size: 0.9rem;
  }
}

.c-btn-faq-header {
  width: 100%;
  padding: map-get($map: $spacers, $key: 3) 0;
  text-align: left;
  color: $body-color;
  &::after {
    content: url(/images/c-arrow-down-icon.svg);
    float: right;
    margin-right: map-get($map: $spacers, $key: 5);
  }
  &:focus {
    // border: none;
    box-shadow: 0 0 $white;
  }
  &:hover {
    text-decoration: none;
  }
}

.c-btn-img {
  transition: filter 0.3s ease;
  &:hover {
    filter: brightness(1.15);
  }
}

.s-isSP {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.s-isTB {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .s-isPC {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.s-isPC {
  @include media-breakpoint-up(lg) {
    display: block;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .s-isTB {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

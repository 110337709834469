.c-ballomn-circle {
  position: relative;
  //   display: inline-block;
  background: $white;
  border-radius: 50% 50%;
  width: 180px;
  height: 180px;
  text-align: center;
  color: map-get($map: $theme-colors, $key: primary);
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -28px;
    margin-top: -15px;
    // border: 15px solid transparent;
    // border-right: 15px solid $white;
    border-right: 30px solid $white;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
  p {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 700;
  }
}
.c-ballomn-circle-deg180 {
  font-weight: 300;
  color: #333333;
  &::before {
    transform: rotate(180deg);
    top: 50%;
    left: 175px;
  }
}
.c-ballomn-circle-deg200 {
  font-weight: 300;
  color: #333333;
  &::before {
    transform: rotate(200deg);
    top: 60%;
    left: 175px;
  }
}
.c-ballomn-circle-deg225 {
  font-weight: 300;
  color: #333333;
  &::before {
    transform: rotate(215deg);
    top: 80%;
    left: 163px;
  }
}

.c-ballomn-circle-deg45 {
  &::before {
    transform: rotate(40deg);
    top: 25%;
    left: -10px;
  }
}
.c-ballomn-circle-deg90 {
  &::before {
    transform: rotate(90deg);
    top: 0;
    left: 50%;
  }
}

.c-speech-icon {
  z-index: 1;
  transform: translate3d(0, 0, 0);
  @include media-breakpoint-down(sm) {
    width: 200%;
  }
  img {
    width: 100%;
  }
}
.c-speech-bubble {
  position: relative;
  padding: map-get($map: $spacers, $key: 4);
  background: #f3f3f3;
  border-radius: 20px;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  p {
    font-weight: 700;
  }
  &.c-speech-bubble-right {
    margin-left: 30px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      @include media-breakpoint-down(md) {
        top: 20%;
      }
      @include media-breakpoint-down(sm) {
        top: 15%;
      }
      left: -20px;
      margin-top: -15px;
      // border: 15px solid transparent;
      // border-right: 15px solid $white;
      border-right: 20px solid #f3f3f3;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
  &.c-speech-bubble-left {
    margin-left: 0px;
    margin-right: 30px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      @include media-breakpoint-down(md) {
        top: 20%;
      }
      @include media-breakpoint-down(sm) {
        top: 15%;
      }
      right: -20px;
      margin-top: -15px;
      // border: 15px solid transparent;
      // border-right: 15px solid $white;
      border-left: 20px solid #f3f3f3;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
  h2 {
    color: $body-color;
    margin-bottom: map-get($map: $spacers, $key: 3);
  }
}

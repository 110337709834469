.c-campaign-price-area {
  h1 {
    color: #171c61;
    font-size: 2.6rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
  }
  .lead {
    color: #171c61;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
  padding: map-get($map: $spacers, $key: 5) 0;
  background: #b3e3dd;
  .c-campaign-price-box {
    width: 800px;
    @include media-breakpoint-down(sm) {
      width: 90%;
    }
    margin-bottom: map-get($map: $spacers, $key: 3);
    h2 {
      font-size: 2.6rem;
    }
    background: $white;
    padding: map-get($map: $spacers, $key: 3);
    p {
      display: inline-block;
      margin-bottom: 0;
      strong {
        font-size: 2.6rem;
        @include media-breakpoint-down(md) {
          font-size: 2.2rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 2.2rem;
        }
        color: map-get($map: $theme-colors, $key: primary);
      }
      span {
        font-size: 0.8rem;
        @include media-breakpoint-down(md) {
          font-size: 0.7rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 0.7rem;
        }
      }
      .vertical {
        padding-top: map-get($map: $spacers, $key: 2);
        writing-mode: vertical-rl;
        @include media-breakpoint-down(sm) {
          font-size: 0.6rem;
        }
      }
    }
  }
}

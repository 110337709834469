$body-color: #040000;
$theme-colors: (
  'primary': #13516e,
  'secondary': #5d8ca3,
  //   'success': $success,
    //   'info': $info,
    //   'warning': $warning,
    //   'danger': $danger,
    //   'light': $light,
    //   'dark': $dark,
    'bg-primary': #002e4f,
  'bg-secondry': #83a3b3,
);

.c-modal-thumb {
  li {
    max-width: 56px;
    @include media-breakpoint-down(sm) {
      max-width: 15%;
      .btn {
        @include media-breakpoint-down(sm) {
          padding: 0;
          img {
            // max-width: 112px;
          }
        }
      }
    }

    .btn {
      padding: 0px;
      background-color: #ffffff;
      transition: filter 0.3s ease;
      border-radius: 0;
      &:hover {
        filter: brightness(1.15);
        // background-color: #eeeeee;
      }
    }
  }
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='8' height='8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

body {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', Meiryo,
    'メイリオ', sans-serif;
  padding-top: 6.2rem;
  @include media-breakpoint-down(md) {
    padding-top: 5.4rem;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 6.2rem;
  }
  &.faq {
    padding-top: map-get($map: $spacers, $key: 5);
  }
  &.online-sales {
    padding-top: map-get($map: $spacers, $key: 4);
  }
  // z-index: 1;
  // transform: translate3d(0, 0, 0);
}

h1 {
  color: map-get($map: $theme-colors, $key: primary);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

h2 {
  color: map-get($map: $theme-colors, $key: primary);
  font-size: 1.4rem;
  font-weight: 700;
}

h3 {
  color: map-get($map: $theme-colors, $key: primary);
  font-size: 1.2rem;
}

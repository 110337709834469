.nav-tabs {
  .nav-item {
    width: 18%;
    margin: 0 map-get($map: $spacers, $key: 3);

    text-align: center;
    @include media-breakpoint-down(md) {
      margin: 0 map-get($map: $spacers, $key: 1);
      width: 30%;
    }
    .nav-link {
      padding: map-get($map: $spacers, $key: 2) map-get($map: $spacers, $key: 5);
      @include media-breakpoint-down(md) {
        padding: map-get($map: $spacers, $key: 2)
          map-get($map: $spacers, $key: 2);
        text-align: center;
      }
      @include media-breakpoint-down(sm) {
        font-size: 0.8rem;
      }
      border: 1px solid #dee2e6;
      background: map-get($map: $theme-colors, $key: bg-secondry);
      color: $white;
      font-weight: 700;

      transition: filter 0.3s ease;
      &:hover {
        filter: brightness(1.15);
      }

      &.active {
        padding: map-get($map: $spacers, $key: 3)
          map-get($map: $spacers, $key: 3);
        @include media-breakpoint-down(sm) {
          padding: map-get($map: $spacers, $key: 3)
            map-get($map: $spacers, $key: 2);
        }

        background: map-get($map: $theme-colors, $key: bg-primary);
      }
    }
  }
}

.c-lead-wquate {
  text-align: center;
  background: url(/images/c-dq-w.png) 0 100% no-repeat;
  background-size: contain;
  font-size: 1.2rem;
  @include media-breakpoint-down(md) {
    font-size: 1.1rem;
  }
}
.c-lead-bquate {
  text-align: center;
  background: url(/images/c-dq-b.png) 50% 100% no-repeat;
  background-size: 48%;
  font-size: 1.4rem;
  @include media-breakpoint-down(lg) {
    // font-size: 1.1rem;
    background-size: 52%;
  }
  @include media-breakpoint-down(md) {
    // font-size: 1.1rem;
    background-size: 65%;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
    background-size: contain;
  }
  font-weight: 700;
  color: $body-color;
}

.c-footer-menu {
  text-align: center;
  padding: map-get($map: $spacers, $key: 5);
  @include media-breakpoint-down(sm) {
    padding: map-get($map: $spacers, $key: 5) 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      padding: 0 map-get($map: $spacers, $key: 3);
      @include media-breakpoint-down(sm) {
        padding: 0 map-get($map: $spacers, $key: 2);
      }
      border-left: 1px solid $body-color;
      font-size: 0.8rem;
      &:first-child {
        border-left: none;
      }
      a {
        color: $body-color;
      }
    }
  }
}

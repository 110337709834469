.online-sales {
  .c-ballomn-container {
    margin-bottom: map-get($map: $spacers, $key: 5);
    background: url(/online-sales/images/l-online-saler-background.png) 50% 65%
      no-repeat;
    height: 460px;
    background-size: cover;
    .container {
      position: relative;
      @include media-breakpoint-down(sm) {
        transform: scale(0.9, 0.9) !important;
      }

      .c-ballomn-circle-deg200 {
        position: absolute;
        top: 50px;
        left: 100px;
        @include media-breakpoint-down(md) {
          left: 20px;
        }
        @include media-breakpoint-down(sm) {
          top: 145px;
          left: 0px;
          //   transform: scale(0.9, 0.9) !important;
        }
      }
      .c-ballomn-circle-deg180 {
        position: absolute;
        top: 240px;
        left: 230px;
        @include media-breakpoint-down(md) {
          left: 160px;
        }
        @include media-breakpoint-down(sm) {
          top: 320px;
          left: 80px;
          //   transform: scale(0.9, 0.9) !important;
        }
      }
      .c-ballomn-circle-deg225 {
        position: absolute;
        top: 50px;
        left: 400px;
        @include media-breakpoint-down(md) {
          left: 260px;
        }
        @include media-breakpoint-down(sm) {
          top: 10px;
          left: 135px;
          //   transform: scale(0.9, 0.9) !important;
        }
      }
    }
  }
  .c-creat-container {
    position: relative;
    height: 460px;
    @include media-breakpoint-down(md) {
      height: 360px;
    }
    @include media-breakpoint-down(sm) {
      height: 270px;
    }
    @include media-breakpoint-down(xs) {
      height: 190px;
    }
  }
  .c-creat-img {
    position: absolute;
    img {
      width: 75%;
    }
    &.c-creat-img01 {
      top: 0;
      left: 0;
    }
    &.c-creat-img02 {
      top: 0;
      right: 0;
      text-align: right;
    }
  }
  .c-ballomn-circle-deg90 {
    position: relative;
    display: inline-block;
    margin: 1.5em 0;
    padding: 7px 10px;
    min-width: 120px;
    max-width: 100%;
    color: #555;
    font-size: 16px;
    background: #fff;
    border: solid 1px #f2b19f;
    box-sizing: border-box;
    background-color: #f9e1d8;
    @include media-breakpoint-down(md) {
      height: 150px;
    }
    @include media-breakpoint-down(sm) {
      height: 180px;
    }
    @include media-breakpoint-down(xs) {
      height: 150px;
    }
    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: 52%;
      margin-left: -15px;
      border: 12px solid transparent;
      border-bottom: 12px solid #f9e1d8;
      z-index: 2;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: -27px;
      left: 52%;
      margin-left: -17px;
      border: 14px solid transparent;
      border-bottom: 14px solid #f2b19f;
      z-index: 1;
    }
    p {
      font-size: 1.4rem;
      font-weight: 300;
    }
  }
  .close-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 1.6rem;
  }
  footer {
    // height: 10px;
    background: rgb(91, 8, 19);
    background: -moz-linear-gradient(
      left,
      rgba(91, 8, 19, 1) 0%,
      rgba(154, 29, 66, 1) 50%,
      rgba(91, 8, 19, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(91, 8, 19, 1) 0%,
      rgba(154, 29, 66, 1) 50%,
      rgba(91, 8, 19, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(91, 8, 19, 1) 0%,
      rgba(154, 29, 66, 1) 50%,
      rgba(91, 8, 19, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b0813', endColorstr='#5b0813',GradientType=1 );
    p {
      visibility: hidden;
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }
}

[data-aos='l-fixed-footer-hide-animation'] {
  // opacity: 1;
  visibility: visible;
  transition-property: transform, opacity, visibility;

  &.aos-animate {
    opacity: 0;
    visibility: hidden;
  }
}

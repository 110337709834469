.l-footer-auth {
  background: #daf1ed;
  // margin-top: map-get($map: $spacers, $key: 3);
  padding: map-get($map: $spacers, $key: 3) 0;
  img {
    width: 15%;
    @include media-breakpoint-down(sm) {
      width: 40%;
    }
    @include media-breakpoint-down(xs) {
      width: 80%;
    }
  }
  p {
    font-size: 0.8rem;
  }
}
.c-footer-cam-area {
  .btn {
    // margin-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    .c-btn-newregist {
      margin-bottom: map-get($map: $spacers, $key: 3);
    }
  }
}

.online-sales {
  .c-card-container {
    position: relative;
    padding-top: 300px;

    @include media-breakpoint-down(sm) {
      padding-top: map-get($map: $spacers, $key: 3);
    }
    margin-bottom: map-get($map: $spacers, $key: 5);
    background: url(/online-sales/images/l-online-saler-img01.png) 100% 50%
      no-repeat;
    background-size: cover;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '　';
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 0) 75%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 0) 75%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 0) 75%,
        rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );
    }
    .c-card {
      padding: map-get($map: $spacers, $key: 3);
      background-color: $white;
      border-radius: 15px;
      box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
      text-align: center;
      font-weight: 700;
      color: #666666;
      span {
        color: #de3828;
        strong {
          font-size: 2rem;
        }
      }
    }
  }
}

.l-tv {
  padding: map-get($map: $spacers, $key: 5);
  @include media-breakpoint-down(md) {
    padding: map-get($map: $spacers, $key: 5) 0;
  }
  h1 {
    color: $body-color;
    strong {
      span {
        color: map-get($map: $theme-colors, $key: primary);
        font-size: 2rem;
      }
    }
  }
  z-index: 1;
  transform: translate3d(0, 0, 0);
}

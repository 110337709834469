.l-sv {
  padding: map-get($map: $spacers, $key: 5) 0;
  background: url(/images/l-sv-bg.png) 50% 100% no-repeat;
  @include media-breakpoint-down(sm) {
    background-position: 40% 100%;
  }
  background-size: cover;
  .c-ballomn-circle {
    left: 700px;
    @include media-breakpoint-down(md) {
      left: 450px;
    }
    @include media-breakpoint-down(sm) {
      left: 320px;
    }
    @include media-breakpoint-down(xs) {
      left: 170px;
    }
  }
  .c-ballomn-circle-deg45 {
    left: 600px;
    @include media-breakpoint-down(md) {
      left: 350px;
    }
    @include media-breakpoint-down(sm) {
      top: 10px;
      left: 270px;
    }
    @include media-breakpoint-down(xs) {
      top: 10px;
      left: 120px;
    }
  }
  z-index: 1;
  transform: translate3d(0, 0, 0);
}

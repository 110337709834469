.l-header {
  transform: translate3d(0, 0, 0);
  .c-logo {
    @include media-breakpoint-down(lg) {
      width: 200px;
      img {
        width: 100%;
      }
    }
    @include media-breakpoint-down(md) {
      width: 150px;
      img {
        width: 100%;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 30%;
      margin-bottom: 0;
      img {
        width: 100%;
      }
    }
    @include media-breakpoint-down(xs) {
      width: 50%;
      margin-bottom: 0;
      img {
        width: 100%;
      }
    }
  }
  .c-service-name {
    margin-bottom: 0;
  }
  padding-top: map-get($map: $spacers, $key: 2);
  background: $white;
  .l-header-subnav {
    margin-bottom: 0;
    padding-top: map-get($map: $spacers, $key: 3);
    padding-left: 0;
    @include media-breakpoint-down(sm) {
      padding-top: map-get($map: $spacers, $key: 0);
    }
    list-style: none;
    .l-header-subnav-item {
      display: inline-block;
      padding-left: map-get($map: $spacers, $key: 3);
      padding-right: map-get($map: $spacers, $key: 3);
      @include media-breakpoint-down(md) {
        font-size: 0.8rem;
      }
      @include media-breakpoint-down(sm) {
        padding-left: map-get($map: $spacers, $key: 3);
        padding-right: map-get($map: $spacers, $key: 3);
        a {
          color: $white;
        }
      }
    }
  }
  .l-header-gnav {
    margin-top: map-get($map: $spacers, $key: 2);
    padding-top: map-get($map: $spacers, $key: 1);
    padding-bottom: map-get($map: $spacers, $key: 1);
    background: map-get($map: $theme-colors, $key: bg-primary);
    .navbar-nav {
      margin-left: auto;
      margin-right: auto;
      .nav-item {
        padding-left: map-get($map: $spacers, $key: 5) * 2;
        padding-right: map-get($map: $spacers, $key: 5) * 2;
        text-align: center;
        @include media-breakpoint-down(md) {
          padding-left: map-get($map: $spacers, $key: 5);
          padding-right: map-get($map: $spacers, $key: 5);
          text-align: center;
          // margin-top: map-get($map: $spacers, $key: 2);
          // margin-bottom: map-get($map: $spacers, $key: 2);
        }
        border-left: 1px solid $white;
        @include media-breakpoint-down(sm) {
          padding-left: map-get($map: $spacers, $key: 5);
          padding-right: map-get($map: $spacers, $key: 5);
          text-align: center;
          border-left: none;
          margin-top: map-get($map: $spacers, $key: 2);
          margin-bottom: map-get($map: $spacers, $key: 2);
        }
        &:first-child {
          border-left: none;
        }
        .nav-link {
          color: $white;
          font-size: 1rem;
          padding: 0;
          transition: all 0.3s ease;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background-color: map-get($map: $theme-colors, $key: bg-primary);
  padding: 5px;
  width: 51px;
  height: 46px;
  box-sizing: border-box;
  position: relative;
  border-radius: 0;
}
.navbar-toggler-icon {
  background-image: none; /* この行で背景画像を無効化 */
  background-color: #ffffff;
  width: 28px;
  height: 4px;
  display: block;
  position: absolute;
  transition: ease 0.5s;
  left: 12px;
}
.navbar-toggler-text {
  font-size: 0.6rem;
  color: #ffffff;
  position: absolute;
  top: 42px;
  left: 9px;
}

/* 3本のバーそれぞれの座標を設定 */
.navbar-toggler-icon:nth-of-type(1) {
  top: 11px;
}
.navbar-toggler-icon:nth-of-type(2) {
  top: 20px;
}
.navbar-toggler-icon:nth-of-type(3) {
  top: 29px;
}

/* メニューが開いている時の　3本のバーそれぞれの座標および角度を設定 */
.navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:nth-of-type(1) {
  top: 20px;
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:nth-of-type(2) {
  opacity: 0;
}
.navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:nth-of-type(3) {
  top: 20px;
  transform: rotate(-45deg);
}

.c-service-name {
  display: inline-block;
  // margin-left: map-get($map: $spacers, $key: 3);
  color: #666666;
  margin-right: map-get($map: $spacers, $key: 3);
  padding: map-get($map: $spacers, $key: 2) map-get($map: $spacers, $key: 2) 0;
  vertical-align: text-top;
  // border-top: 1px solid $body-color;
  // border-bottom: 1px solid $body-color;
  font-size: 1.2rem;
  @include media-breakpoint-down(lg) {
    font-size: 1rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 0.8rem;
  }
  @include media-breakpoint-down(sm) {
    // display: block;
    font-size: 0.7rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: bottom;
  }
}

.c-list-icon {
  list-style: none;
  padding-left: 0;
  margin-bottom: map-get($map: $spacers, $key: 3);
  li {
    font-size: 0.9rem;
    margin-bottom: map-get($map: $spacers, $key: 2);
    img {
      display: inline-block;
      margin-right: map-get($map: $spacers, $key: 1);
    }
  }
}

.c-yplayer-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  // @include media-breakpoint-down(md) {
  //   width: 100%;
  //   height: 100px;
  // }
  // @include media-breakpoint-down(sm) {
  //   width: 100%;
  //   height: 100px;
  // }
  border: 1px solid #333333;
  margin: 0 auto map-get($map: $spacers, $key: 5);
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.c-flex-item {
  width: 30%;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  text-align: center;
  margin: 0 1% map-get($map: $spacers, $key: 3);
  h3 {
    font-weight: 700;
  }
}

.c-item-img {
  //   width: 50%;
  margin: 0 auto;
  margin-bottom: map-get($map: $spacers, $key: 3);
  img {
    height: 120px;
    @include media-breakpoint-down(md) {
      height: 80px;
    }
  }
}

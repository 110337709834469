.l-faq {
  h2 {
    margin-bottom: map-get($map: $spacers, $key: 3);
    color: $body-color;
  }
  .card {
    border: none;
    border-bottom: 1px solid #c9caca;
    border-radius: 0;
  }
  .card-header {
    border-top: 1px solid #c9caca;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background: $white;
    // border-bottom: 1px solid #c9caca;
    border-bottom: none;
  }
  .card-body {
    padding: 0 0 map-get($map: $spacers, $key: 3);
  }
}

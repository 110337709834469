.l-fv {
  // height: 100vh;
  .l-fv-container {
    height: 65vh;
    max-height: 500px;
    padding: map-get($map: $spacers, $key: 5);
    padding-top: map-get($map: $spacers, $key: 5) * 2;
    @include media-breakpoint-down(md) {
      height: auto;
    }
    @include media-breakpoint-down(sm) {
      padding: map-get($map: $spacers, $key: 4);
      height: 52vh;
    }
    background: url(/images/l-fv-bg.png) 50% 85% no-repeat,
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 1%,
        rgba(255, 255, 255, 1) 100%
      );
    background-size: cover;
  }
}

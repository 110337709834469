.c-headline {
  margin-bottom: map-get($map: $spacers, $key: 5) +
    map-get($map: $spacers, $key: 3);
  padding-top: map-get($map: $spacers, $key: 3);
  padding-bottom: map-get($map: $spacers, $key: 3);
  background: map-get($map: $theme-colors, $key: bg-primary);
  color: $white;
}

.c-minheadline {
  margin-bottom: map-get($map: $spacers, $key: 3);
  padding: map-get($map: $spacers, $key: 1);
  background: map-get($map: $theme-colors, $key: bg-secondry);
  color: $white;
}

.online-sales {
  .c-headline {
    padding: map-get($map: $spacers, $key: 4) 0 map-get($map: $spacers, $key: 3);
    color: $white;
    text-align: center;
    background: rgb(91, 8, 19);
    background: -moz-linear-gradient(
      left,
      rgba(91, 8, 19, 1) 0%,
      rgba(154, 29, 66, 1) 50%,
      rgba(91, 8, 19, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(91, 8, 19, 1) 0%,
      rgba(154, 29, 66, 1) 50%,
      rgba(91, 8, 19, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(91, 8, 19, 1) 0%,
      rgba(154, 29, 66, 1) 50%,
      rgba(91, 8, 19, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b0813', endColorstr='#5b0813',GradientType=1 );
    h1 {
      color: $white;
      font-size: 3rem;
      strong {
        font-size: 4rem;
      }
    }
    font-size: 1.6rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }
  }
  h2 {
    margin-bottom: map-get($map: $spacers, $key: 5);
    text-align: center;
    font-size: 1.6rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }
    font-weight: 300;
    line-height: 1.8;
    color: #590c13;
  }
}

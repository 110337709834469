.l-fixed-footer-wrapper {
  position: fixed;
  z-index: 1000;
  bottom: 0%;
  right: 0;
  width: 30%;
  opacity: 0.5;
  // transform: translate3d(0, 0, 0);
  @include media-breakpoint-down(lg) {
    width: 30%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    bottom: 0%;
    opacity: 1;
  }
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
}
.l-fixed-footer {
  width: 100%;
  padding: map-get($map: $spacers, $key: 3) map-get($map: $spacers, $key: 3) 0;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  background-color: map-get($map: $theme-colors, $key: bg-primary);
  .btn {
    font-size: 1.2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
    &.c-btn-newregist {
      padding-top: map-get($map: $spacers, $key: 2);
    }
    &.c-btn-free {
      padding-top: map-get($map: $spacers, $key: 2);
    }
    // &.c-btn-newregist {
    //   @include media-breakpoint-down(sm) {
    //     padding-top: 42px;
    //   }
    // }
  }
}

.c-cam-area {
  padding: map-get($map: $spacers, $key: 4) map-get($map: $spacers, $key: 3)
    map-get($map: $spacers, $key: 2);
  @include media-breakpoint-down(sm) {
    // padding: map-get($map: $spacers, $key: 3) 0 map-get($map: $spacers, $key: 3);
    padding: map-get($map: $spacers, $key: 4) 0 map-get($map: $spacers, $key: 2);
  }
  background-color: map-get($map: $theme-colors, $key: bg-primary);
  color: $white;
  .c-cam-area-container {
    width: 780px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    margin: 0 auto;
  }
  .c-headline {
    margin-bottom: map-get($map: $spacers, $key: 3);
  }
}
